import React, { Component } from 'react';
import {NavLink} from "react-router-dom";
import imganen1 from './empresa1.jpg';
import imganen2 from './empresa2.png';
import './NuestrasEmpresas.css';

class NuestrasEmpresas extends Component {

  render()
  {
    return (
      <div className="container-fluid NuestrasEmpresas">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-4 animated fadeInLeft">
          <div className="lista_botones">
            <div className="list-group lista_botones">
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestras-empresas" activeClassName="active">NUESTRAS EMPRESAS</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/mision-y-vision" activeClassName="active">MISIÓN Y VISIÓN</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/por-que-elegirnos" activeClassName="active">POR QUE ELEGIRNOS</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestra-experiencia" activeClassName="active">NUESTRA EXPERIENCIA</NavLink>
            </div>
          </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 animated fadeInDownBig">
            <h3>Nuestra Empresa</h3>
            <p>Somos la empresa que brinda Soporte en el sector de la Tecnología.</p>
            <p>Desde 2019, nos constituimos como la más grande y experimentada empresa de servicios del Perú. Hemos desarrollado proyectos en todos los sectores de la Tecnología: esperamos liderar en el Servicio e implementación de proyectos integrales altamente confiables y exitosos.</p>
            <p>Trabajamos con nuestros clientes desde la concepción de sus proyectos,idea y asesoramientos, brindando un gran soporte que genera valor en sus negocios al optimizar recursos, plazos y costos.</p>
            <p>Nuestra experiencia, profesionalismo y constante actualización tecnológica, hace que podamos garantizar a nuestros clientes la entrega de un proyecto con los más altos estándares de calidad y entregando en plazo pactado, respetando y protegiendo las propuestas del cliente.</p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 animated fadeInDownBig">
            <div className="card" style={{width: '100%',border:'none',marginBottom:'10px'}}>
              <img src={imganen1} className="card-img-top" alt="Imagen 1"/>
            </div>
            <div className="card" style={{width: '100%',border:'none'}}>
              <img src={imganen2} className="card-img-top" alt="Imagen 1"/>
            </div>
          </div>
        </div>
        <hr style={{height: '2px',background: 'darkgray'}}/>
      </div>
    );
  }
}

export default NuestrasEmpresas;
