import React, { Component } from 'react';
import {NavLink} from "react-router-dom";
import imganen1 from './impresora1.jpg';
import imganen2 from './impresora2.jpg';
import imganen3 from './impresora3.jpg';
import './MantenimientoReparaciondeImpresorasyCopiadoras.css';

class MantenimientoReparaciondeImpresorasyCopiadoras extends Component {

  render()
  {
    return (
      <div className="container-fluid MantenimientoReparaciondeImpresorasyCopiadoras">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-3 animated fadeInLeft">
          <div className="lista_botones">
            <div className="list-group lista_botones">
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestros-servicios/mantenimiento-preventivo-de-pc" activeClassName="active">MANTENIMIENTO PREVENTICO Y CORRECTIVO DE PC</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestros-servicios/mantenimiento-y-reparacion-de-laptop" activeClassName="active">MANTENIMIENTO Y REPARACIÓN DE LAPTOP</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestros-servicios/mantenimiento-y-reparacion-de-proyectores" activeClassName="active">MANTENIMIENTO Y REPARACIÓN DE PROYECTORES</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestros-servicios/mantenimiento-y-reparacion-de-impresoras-y-copiadoras" activeClassName="active">MANTENIMIENTO Y REPARACIÓN DE IMPRESORAS Y COPIADORAS</NavLink>
            </div>
          </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 animated fadeInDownBig">
              <h3>Nuestros Servicios</h3>
              <div className="parrafo">
                <h4>MANTENIMIENTO Y REPARACIÓN DE IMPRESORAS Y COPIADORAS</h4>
                <p>Reparación y mantenimiento de impresoras y plotters, todas las marcas y modelos.</p>
                <p>Reparación de impresoras de todo los modelos y marcas: INKJET, LASER, MATRICIALES, MULTIFUNCION , PLOTTERS, EPSON, HP, CANON, LEXMARK, XEROX, SAMSUNG equipos PUNTOS DE VENTA LINEAS TM, etc.</p>
                <p>Nos dedicamos a la instalación y soporte técnico de Proyectores, Ecran, Racks, Cableado Multimedia.</p>
                <p>Todo nuestros servicios cuentan con garantía,  venta de lámparas y repuestos para proyectores.</p>
                <p>Atascos de papel.</p>
                <p>Calidad de impresión, manchas.</p>
                <p>Eventos repetitivos, ruidos estraños internamente.</p>
                <p>Elaboración de presupuestos sin compromiso.</p>
                <p>Sustitución de piezas rotas o desgastadas.</p>
                <p>Asistencia telefónica para una solución remota.</p>
                <p>Nuestro objetivo es brindar a nuestros clientes servicios y productos de calidad a precios razonables y muy competitivos. Utilizando siempre repuestos originales, y personal técnico capacitado. Todas nuestras reparaciones cuentan con una garantía de 90 días, por cualquier falla en los repuestos remplazados así como también en la mano de obra. Nuestro objetivo principal no es conseguir gran cantidad de nuevos clientes todos los días, sino mantener en cartera los clientes que ya han confiado sus equipos a nuestro servicio técnico.</p>
                <p>Para lograr precios de reparaciones razonables, nuestra forma de trabajo es evitar el cambio de partes muy costosas en los equipos. Para ello, nuestro personal está capacitado para detectar cual es el componente electrónico, electromecánico o mecánico que causa la falla del equipo a reparar. Y de esta forma minimizar los costos por cambio de partes al mínimo posible.</p>
              </div>

          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 animated fadeInDownBig">
            <div className="card" style={{width: '100%',border:'none',marginBottom:'10px'}}>
              <img src={imganen1} className="card-img-top" alt="Imagen 1"/>
            </div>
            <div className="card" style={{width: '100%',border:'none',marginBottom:'10px'}}>
              <img src={imganen2} className="card-img-top" alt="Imagen 1"/>
            </div>
            <div className="card" style={{width: '100%',border:'none',marginBottom:'10px'}}>
              <img src={imganen3} className="card-img-top" alt="Imagen 1"/>
            </div>
          </div>
        </div>
        <hr style={{height: '2px',background: 'darkgray'}}/>
      </div>
    );
  }
}

export default MantenimientoReparaciondeImpresorasyCopiadoras;
