import React, { Component } from 'react';
import {NavLink} from "react-router-dom";
import producto1 from './copia1.jpg';
import producto2 from './copia2.jpg';
import producto3 from './copia3.jpg';
import producto4 from './copia4.jpg';
import producto5 from './copia5.jpg';
import producto6 from './copia6.jpg';
import producto7 from './copia7.jpg';
import producto8 from './copia8.jpg';
import './Copiadoras.css';

class Copiadoras extends Component {

  render()
  {
    return (
      <div className="container-fluid Copiadoras">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-3 animated fadeInLeft">
          <div className="lista_botones">
            <div className="list-group lista_botones">
              <NavLink type="button" className="list-group-item list-group-item-action" to="/repuestos-para-impresoras" activeClassName="active">REPUESTOS PARA IMPRESORAS</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/impresoras" activeClassName="active">IMPRESORAS</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/copiadoras" activeClassName="active">COPIADORAS</NavLink>
            </div>
          </div>
          </div>

          <div className="col-lg-9 col-md-9 col-sm-9 animated fadeInDownBig">
          <h3>Copiadoras</h3>

          <div className="row">

            <div className="col-lg-3 col-md-3 col-sm-12 contenedor_producto animated wobble">
              <img src={producto1} width="100%" height="300px" alt=""/>
              <p className="btn btn-secondary">TASKalfa 4012i - B/N</p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 contenedor_producto animated bounceIn">
              <img src={producto2} width="100%" height="300px" alt=""/>
              <p className="btn btn-secondary">TASKalfa 6002i - B/N</p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 contenedor_producto animated bounceInDown">
              <img src={producto3} width="100%" height="300px" alt=""/>
              <p className="btn btn-secondary">TASKalfa 8002i - B/N</p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 contenedor_producto animated jello">
              <img src={producto4} width="100%" height="300px" alt=""/>
              <p className="btn btn-secondary">Ecosys M3655idn - B/N</p>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-12 contenedor_producto animated wobble">
              <img src={producto5} width="100%" height="300px" alt=""/>
              <p className="btn btn-secondary">TASKalfa 406ci/Stándar - Color</p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 contenedor_producto animated bounceIn">
              <img src={producto6} width="100%" height="300px" alt=""/>
              <p className="btn btn-secondary">Ecosys M8124cidn - Color</p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 contenedor_producto animated bounceInDown">
              <img src={producto7} width="100%" height="300px" alt=""/>
              <p className="btn btn-secondary">TASKalfa 6052ci - Color</p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 contenedor_producto animated jello">
              <img src={producto8} width="100%" height="300px" alt=""/>
              <p className="btn btn-secondary">TASKalfa 3552ci - Color</p>
            </div>

          </div>


          </div>

        </div>
        <hr style={{height: '2px',background: 'darkgray'}}/>
      </div>
    );
  }
}

export default Copiadoras;
