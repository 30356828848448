import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './animate.css';
import DatosEmpresa from './componentes/DatosEmpresa/DatosEmpresa';
import Navegador from './componentes/Navegador/Navegador';
import SlidePrincipal from './componentes/SlidePrincipal/SlidePrincipal';
import IconosServicios from './componentes/IconosServicios/IconosServicios';
import SlideVideos from './componentes/SlideVideos/SlideVideos';
import SlideProductos from './componentes/SlideProductos/SlideProductos';
import SlideClientes from './componentes/SlideClientes/SlideClientes';


import MisionVision from './componentes/MisionVision/MisionVision';
import NuestrasEmpresas from './componentes/NuestrasEmpresas/NuestrasEmpresas';
import PorqueElegirnos from './componentes/PorqueElegirnos/PorqueElegirnos';
import NuestraExperiencia from './componentes/NuestraExperiencia/NuestraExperiencia';
import CopyRight from './componentes/CopyRight/CopyRight';
import Noticias from './componentes/Noticias/Noticias';
import Contacto from './componentes/Contacto/Contacto';

// import NuestrosServicios from './componentes/NuestrosServicios/NuestrosServicios';
import MantenimientoPreventivodePc from './componentes/NuestrosServicios/MantenimientoPreventivodePc/MantenimientoPreventivodePc';
import MantenimientoReparaciondeProyectores from './componentes/NuestrosServicios/MantenimientoReparaciondeProyectores/MantenimientoReparaciondeProyectores';
import MantenimientoReparaciondeImpresorasyCopiadoras from './componentes/NuestrosServicios/MantenimientoReparaciondeImpresorasyCopiadoras/MantenimientoReparaciondeImpresorasyCopiadoras';
import MantenimientoReparaciondeLaptop from './componentes/NuestrosServicios/MantenimientoReparaciondeLaptop/MantenimientoReparaciondeLaptop';


// import AlquilerdeEquipos from './componentes/AlquilerdeEquipos/AlquilerdeEquipos';
import OutsorcingdeImpresion from './componentes/AlquilerdeEquipos/OutsorcingdeImpresion/OutsorcingdeImpresion';
import OutsorcingdeLaptop from './componentes/AlquilerdeEquipos/OutsorcingdeLaptop/OutsorcingdeLaptop';

// import ServiciodeRedesyCamaras from './componentes/ServiciodeRedesyCamaras/ServiciodeRedesyCamaras';
import CableadodeRed from './componentes/ServiciodeRedesyCamaras/CableadodeRed/CableadodeRed';
import InstalaciondeCamaras from './componentes/ServiciodeRedesyCamaras/InstalaciondeCamaras/InstalaciondeCamaras';

import RepuestosparaImpresoras from './componentes/NuestrosProductos/RepuestosparaImpresoras/RepuestosparaImpresoras';
import Copiadoras from './componentes/NuestrosProductos/Copiadoras/Copiadoras';
import Impresoras from './componentes/NuestrosProductos/Impresoras/Impresoras';
// import Footer from './componentes/Footer/Footer';
import Mapa from './componentes/Mapa/Mapa';


function App() {

  const Home = () => (
    <div className="content-fluid">
      <DatosEmpresa/>
      <Navegador/>
      <SlidePrincipal/>
      <IconosServicios/>
      <SlideProductos/>
      <SlideVideos/>
      <SlideClientes/>
      <Mapa/>
      <CopyRight/>
    </div>
  );

  const NuestrasEmpresasComponente = () => (
    <div className="content-fluid">
      <DatosEmpresa/>
      <Navegador/>
      <SlidePrincipal/>
      <NuestrasEmpresas/>
      <Mapa/>
      <CopyRight/>
    </div>
  );

  const MisionVisionComponente = () => (
    <div className="content-fluid">
      <DatosEmpresa/>
      <Navegador/>
      <SlidePrincipal/>
      <MisionVision/>
      <Mapa/>
      <CopyRight/>
    </div>
  );

  const PorqueElegirnosComponente = () => (
    <div className="content-fluid">
      <DatosEmpresa/>
      <Navegador/>
      <SlidePrincipal/>
      <PorqueElegirnos/>
      <Mapa/>
      <CopyRight/>
    </div>
  );

  const NuestraExperienciaComponente = () => (
    <div className="content-fluid">
      <DatosEmpresa/>
      <Navegador/>
      <SlidePrincipal/>
      <NuestraExperiencia/>
      <Mapa/>
      <CopyRight/>
    </div>
  );

  const NoticiasComponente = () => (
    <div className="content-fluid">
      <DatosEmpresa/>
      <Navegador/>
      <SlidePrincipal/>
      <Noticias/>
      <Mapa/>
      <CopyRight/>
    </div>
  );

  const ContactoComponente = () => (
    <div className="content-fluid">
      <DatosEmpresa/>
      <Navegador/>
      <SlidePrincipal/>
      <Contacto/>
      <Mapa/>
      <CopyRight/>
    </div>
  );

  // const NuestrosServiciosComponente = () => (
  //   <div className="content-fluid">
  //     <DatosEmpresa/>
  //     <Navegador/>
  //     <SlidePrincipal/>
  //     <NuestrosServicios/>
  //     <Mapa/>
  //     <CopyRight/>
  //   </div>
  // );

  const MantenimientoPreventivodePcComponente = () => (
    <div className="content-fluid">
      <DatosEmpresa/>
      <Navegador/>
      <SlidePrincipal/>
      <MantenimientoPreventivodePc/>
      <Mapa/>
      <CopyRight/>
    </div>
  );

  const MantenimientoReparaciondeProyectoresComponente = () => (
    <div className="content-fluid">
      <DatosEmpresa/>
      <Navegador/>
      <SlidePrincipal/>
      <MantenimientoReparaciondeProyectores/>
      <Mapa/>
      <CopyRight/>
    </div>
  );

  const MantenimientoReparaciondeLaptopComponente = () => (
    <div className="content-fluid">
      <DatosEmpresa/>
      <Navegador/>
      <SlidePrincipal/>
      <MantenimientoReparaciondeLaptop/>
      <Mapa/>
      <CopyRight/>
    </div>
  );

  const MantenimientoReparaciondeImpresorasyCopiadorasComponente = () => (
    <div className="content-fluid">
      <DatosEmpresa/>
      <Navegador/>
      <SlidePrincipal/>
      <MantenimientoReparaciondeImpresorasyCopiadoras/>
      <Mapa/>
      <CopyRight/>
    </div>
  );

  // const AlquilerdeEquiposComponente = () => (
  //   <div className="content-fluid">
  //     <DatosEmpresa/>
  //     <Navegador/>
  //     <SlidePrincipal/>
  //     <AlquilerdeEquipos/>
  //     <Mapa/>
  //     <CopyRight/>
  //   </div>
  // );

  const OutsorcingdeImpresionComponente = () => (
    <div className="content-fluid">
      <DatosEmpresa/>
      <Navegador/>
      <SlidePrincipal/>
      <OutsorcingdeImpresion/>
      <Mapa/>
      <CopyRight/>
    </div>
  );

  const OutsorcingdeLaptopComponente = () => (
    <div className="content-fluid">
      <DatosEmpresa/>
      <Navegador/>
      <SlidePrincipal/>
      <OutsorcingdeLaptop/>
      <Mapa/>
      <CopyRight/>
    </div>
  );

  // const ServiciodeRedesyCamarasComponente = () => (
  //   <div className="content-fluid">
  //     <DatosEmpresa/>
  //     <Navegador/>
  //     <SlidePrincipal/>
  //     <ServiciodeRedesyCamaras/>
  //     <Mapa/>
  //     <CopyRight/>
  //   </div>
  // );

  const CableadodeRedComponente = () => (
    <div className="content-fluid">
      <DatosEmpresa/>
      <Navegador/>
      <SlidePrincipal/>
      <CableadodeRed/>
      <Mapa/>
      <CopyRight/>
    </div>
  );

  const InstalaciondeCamarasComponente = () => (
    <div className="content-fluid">
      <DatosEmpresa/>
      <Navegador/>
      <SlidePrincipal/>
      <InstalaciondeCamaras/>
      <Mapa/>
      <CopyRight/>
    </div>
  );

  const RepuestosparaImpresorasComponente = () => (
    <div className="content-fluid">
      <DatosEmpresa/>
      <Navegador/>
      <SlidePrincipal/>
      <RepuestosparaImpresoras/>
      <Mapa/>
      <CopyRight/>
    </div>
  );

  const CopiadorasComponente = () => (
    <div className="content-fluid">
      <DatosEmpresa/>
      <Navegador/>
      <SlidePrincipal/>
      <Copiadoras/>
      <Mapa/>
      <CopyRight/>
    </div>
  );

  const ImpresorasComponente = () => (
    <div className="content-fluid">
      <DatosEmpresa/>
      <Navegador/>
      <SlidePrincipal/>
      <Impresoras/>
      <Mapa/>
      <CopyRight/>
    </div>
  );

  const NoMatch = ({ location }) => (
    <div>
      <h3>
        Página no encontrada <code>{location.pathname}</code>
      </h3>
    </div>
  );

  return (
        <Router>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/nuestras-empresas" exact component={NuestrasEmpresasComponente} />
              <Route path="/mision-y-vision" exact component={MisionVisionComponente} />
              <Route path="/por-que-elegirnos" exact component={PorqueElegirnosComponente} />
              <Route path="/nuestra-experiencia" exact component={NuestraExperienciaComponente} />
              <Route path="/noticias" exact component={NoticiasComponente} />
              <Route path="/contacto" exact component={ContactoComponente} />

              <Route path="/nuestros-servicios" exact component={MantenimientoPreventivodePcComponente} />
              <Route path="/nuestros-servicios/mantenimiento-preventivo-de-pc" exact component={MantenimientoPreventivodePcComponente} />
              <Route path="/nuestros-servicios/mantenimiento-y-reparacion-de-proyectores" exact component={MantenimientoReparaciondeProyectoresComponente} />
              <Route path="/nuestros-servicios/mantenimiento-y-reparacion-de-laptop" exact component={MantenimientoReparaciondeLaptopComponente} />
              <Route path="/nuestros-servicios/mantenimiento-y-reparacion-de-impresoras-y-copiadoras" exact component={MantenimientoReparaciondeImpresorasyCopiadorasComponente} />

              <Route path="/alquiler-de-equipos" exact component={OutsorcingdeImpresionComponente} />
              <Route path="/alquiler-de-equipos/outsorcing-de-impresion" exact component={OutsorcingdeImpresionComponente} />
              <Route path="/alquiler-de-equipos/outsorcing-de-laptop" exact component={OutsorcingdeLaptopComponente} />

              <Route path="/servicio-de-redes-y-camaras" exact component={CableadodeRedComponente} />
              <Route path="/servicio-de-redes-y-camaras/cableado-de-red" exact component={CableadodeRedComponente} />
              <Route path="/servicio-de-redes-y-camaras/instalacion-de-camaras" exact component={InstalaciondeCamarasComponente} />

              <Route path="/repuestos-para-impresoras" exact component={RepuestosparaImpresorasComponente} />
              <Route path="/impresoras" exact component={ImpresorasComponente} />
              <Route path="/copiadoras" exact component={CopiadorasComponente} />

              <Route path="/navegador/:urlid" component={Navegador} />
              <Redirect from="/login" to="/" />
              <Route component={NoMatch} />
            </Switch>
        </Router>
  );
}

export default App;
