import React, { Component } from 'react';
import {NavLink} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import imganen1 from './red1.jpg';
import imganen2 from './red2.jpg';
import './CableadodeRed.css';

class CableadodeRed extends Component {

  render()
  {
    return (
      <div className="container-fluid CableadodeRed">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-3 animated fadeInLeft">
          <div className="lista_botones">
            <div className="list-group lista_botones">
              <NavLink type="button" className="list-group-item list-group-item-action" to="/servicio-de-redes-y-camaras/cableado-de-red" activeClassName="active">CABLEADO DE RED</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/servicio-de-redes-y-camaras/instalacion-de-camaras" activeClassName="active">INSTALACIÓN DE CÁMARAS</NavLink>
            </div>
          </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 animated fadeInDownBig">
          <h3>SERVICIO DE REDES Y CÁMARAS</h3>
          <div className="parrafo">
            <p> En HELP SYSTEM realizamos instalación de cableado estructurado de Voz y Data, Eléctrico. Enlaces de fibra optica. Mantenimiento de red y cableados  eléctrico. ofrecemos soluciones y productos para el cableado de oficinas, con el fin de ofrecerle una conectividad de todos tus equipos activos. Administramos equipos de infraestructura de red, switches, firewalls, servidores y periféricos, entre otros.</p>
          </div>
          <div className="parrafo">
            <h4>Profesionales especializados ofrecemos los siguientes servicios:</h4>
            <ul>
              <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Planeación de Red</li>
              <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Análisis de Red</li>
              <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Cableado Estructurado</li>
              <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Sistemas de Comunicación de datos</li>
              <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Puntos de Voz</li>
              <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Puntos de Datos</li>
              <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Redes inalámbricas</li>
              <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Venta de suministro de UPS</li>
              <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Mantenimiento de tableros de RACK</li>
              <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Mantenimiento preventivo y correctivo de gabinetes de red</li>
            </ul>
          </div>

          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 animated fadeInDownBig">
            <div className="card" style={{width: '100%',border:'none',marginBottom:'10px'}}>
              <img src={imganen1} className="card-img-top" alt="Imagen 1"/>
            </div>
            <div className="card" style={{width: '100%',border:'none'}}>
              <img src={imganen2} className="card-img-top" alt="Imagen 1"/>
            </div>
          </div>
        </div>
        <hr style={{height: '2px',background: 'darkgray'}}/>
      </div>
    );
  }
}

export default CableadodeRed;
