import React, { Component } from 'react';
import {NavLink} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import imganen1 from './alquiler4.jpg';
import imganen2 from './alquiler5.jpg';
import './OutsorcingdeLaptop.css';

class OutsorcingdeLaptop extends Component {

  render()
  {
    return (
      <div className="container-fluid OutsorcingdeLaptop">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-3 animated fadeInLeft">
          <div className="lista_botones">
            <div className="list-group lista_botones">
              <NavLink type="button" className="list-group-item list-group-item-action" to="/alquiler-de-equipos/outsorcing-de-impresion" activeClassName="active">OUTSORCING DE IMPRESIÓN</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/alquiler-de-equipos/outsorcing-de-laptop" activeClassName="active">OUTSORCING DE LAPTOP</NavLink>
            </div>
          </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 animated fadeInDownBig">
          <h3>Alquiler de Equipos</h3>
          <div className="parrafo">
            <h4>OUTSOURCING DE LAPTOPS</h4>
            <p> Personal técnico calificado, colocado en las instalaciones de su empresa realizando funciones de mesa de ayuda, solucionando problemas de hardware, software, redes comunicaciones y parte eléctrica. Nuestra valiosa experiencia nos permite seguir fortaleciendo a nuestros clientes en sus procesos de modernización tecnológica, optimizando sus procesos.</p>

            <h4>GANARÁ CON ESTE SERVICIO DE OUTSOURCING</h4>
            <p>Visitas ilimitadas a su empresa por cualquier evento de fallas de equipos de cómputo y sin límite de horas.</p>
            <p>Solución para sus equipos de cómputo en menos de 2hs. desde la llamada.</p>
            <p>Reducción de tiempos muertos al mínimo por fallas de equipos de cómputo e impresoras.</p>
            <p>Soporte remoto y vía telefónica.</p>
            <p>Mejora estratégica, Fluidez y Agilidad en la solución de los problemas con equipos de cómputo y otros recursos.</p>
            <p>Asesoría Técnica Permanente.</p>
          </div>
          <div className="parrafo">
            <h4>MODALIDADES DEL SERVICIO</h4>
            <ul>
              <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Tiempo parcial (servicios por horas)</li>
              <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Tiempo completo</li>
            </ul>
          </div>

          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 animated fadeInDownBig">
            <div className="card" style={{width: '100%',border:'none',marginBottom:'10px'}}>
              <img src={imganen1} className="card-img-top" alt="Imagen 1"/>
            </div>
            <div className="card" style={{width: '100%',border:'none'}}>
              <img src={imganen2} className="card-img-top" alt="Imagen 1"/>
            </div>
          </div>
        </div>
        <hr style={{height: '2px',background: 'darkgray'}}/>
      </div>
    );
  }
}

export default OutsorcingdeLaptop;
