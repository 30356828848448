import React, { Component } from 'react';
import {NavLink} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import imganen1 from './proyector1.jpg';
import imganen2 from './proyector2.jpg';
import imganen3 from './proyector3.jpg';
import './MantenimientoReparaciondeProyectores.css';

class MantenimientoReparaciondeProyectores extends Component {

  render()
  {
    return (
      <div className="container-fluid MantenimientoReparaciondeProyectores">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-3 animated fadeInLeft">
          <div className="lista_botones">
            <div className="list-group lista_botones">
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestros-servicios/mantenimiento-preventivo-de-pc" activeClassName="active">MANTENIMIENTO PREVENTICO Y CORRECTIVO DE PC</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestros-servicios/mantenimiento-y-reparacion-de-laptop" activeClassName="active">MANTENIMIENTO Y REPARACIÓN DE LAPTOP</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestros-servicios/mantenimiento-y-reparacion-de-proyectores" activeClassName="active">MANTENIMIENTO Y REPARACIÓN DE PROYECTORES</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestros-servicios/mantenimiento-y-reparacion-de-impresoras-y-copiadoras" activeClassName="active">MANTENIMIENTO Y REPARACIÓN DE IMPRESORAS Y COPIADORAS</NavLink>
            </div>
          </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 animated fadeInDownBig">
              <h3>Nuestros Servicios</h3>
              <div className="parrafo">
                <h4>Mantenimiento y reparación de Proyectores</h4>
                <p>Ofrecemos servicio de mantenimiento y reparación a proyectores Panasonic, Epson, Dell, Sony, LG, BenQ, Viewsonic y más. Realizamos mantenimiento correctivo y reparación de fallas de fuente alimentación, cambio de lámparas, lentes, sistemas de refrigeración y ventilación.</p>
                <p>Solicita una visita de nuestros expertos para una mejor cotización.</p>
                <p>Nos dedicamos a la instalación y soporte técnico de Proyectores, Ecran, Racks, Cableado Multimedia.</p>
                <p>Todo nuestros servicios cuentan con garantía,  venta de lámparas y repuestos para proyectores.</p>
              </div>
              <div className="parrafo">
                <h4>Nuestro Servicio técnico te ofrece:</h4>
                <ul>
                  <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Instalación y soporte técnico de proyectores, ecrans, racks.</li>
                  <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Mantenimiento preventivo y correctivo de proyectores.</li>
                  <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Servicios de mantenimiento en su oficina.</li>
                  <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Revisión y reparación de proyectores.</li>
                  <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Venta de Repuestos y Suministros (Lámparas para proyectores todas las marcas)</li>
                </ul>
              </div>

          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 animated fadeInDownBig">
            <div className="card" style={{width: '100%',border:'none',marginBottom:'10px'}}>
              <img src={imganen1} className="card-img-top" alt="Imagen 1"/>
            </div>
            <div className="card" style={{width: '100%',border:'none',marginBottom:'10px'}}>
              <img src={imganen2} className="card-img-top" alt="Imagen 1"/>
            </div>
            <div className="card" style={{width: '100%',border:'none',marginBottom:'10px'}}>
              <img src={imganen3} className="card-img-top" alt="Imagen 1"/>
            </div>
          </div>
        </div>
        <hr style={{height: '2px',background: 'darkgray'}}/>
      </div>
    );
  }
}

export default MantenimientoReparaciondeProyectores;
