import React, { Component } from 'react';
import {NavLink} from "react-router-dom";
import imganen1 from './experiencias2.jpg';
import imganen2 from './experiencias1.jpg';
import './PorqueElegirnos.css';

class PorqueElegirnos extends Component {

  render()
  {
    return (
      <div className="container-fluid PorqueElegirnos">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-4 animated fadeInLeft">
          <div className="lista_botones">
            <div className="list-group lista_botones">
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestras-empresas" activeClassName="active">NUESTRAS EMPRESAS</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/mision-y-vision" activeClassName="active">MISIÓN Y VISIÓN</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/por-que-elegirnos" activeClassName="active">POR QUE ELEGIRNOS</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestra-experiencia" activeClassName="active">NUESTRA EXPERIENCIA</NavLink>
            </div>
          </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 animated fadeInDownBig">
            <h3>POR QUE ELEGIRNOS</h3>
            <p>Tendrá un socio tecnológico estratégico, que le ayudará a desarrollar e implementar nuevas tecnologías en forma oportuna y eficaz, así como también mantenimiento preventivo y correctivo de su equipos cómputo, servidores e impresoras</p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 animated fadeInDownBig">
            <div className="card" style={{width: '80%',border:'none',marginBottom:'10px',height:'200px'}}>
              <img src={imganen1} className="card-img-top" alt="Imagen 1"/>
            </div>
            <div className="card" style={{width: '80%',border:'none',height:'200px'}}>
              <img src={imganen2} className="card-img-top" alt="Imagen 1"/>
            </div>
          </div>
        </div>
        <hr style={{height: '2px',background: 'darkgray'}}/>
      </div>
    );
  }
}

export default PorqueElegirnos;
