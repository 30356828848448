import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersCog,faNetworkWired,faHandsHelping,faShoppingCart } from '@fortawesome/free-solid-svg-icons';
// import { faIdCard, faClock } from '@fortawesome/free-regular-svg-icons';
// import { faFacebookF,faTwitter,faInstagram } from '@fortawesome/free-brands-svg-icons';
import './IconosServicios.css';

class IconosServicios extends Component {

  render()
  {
    return (
      <div className="IconosServicios">
        <div className="fila-row">
          <div className="col-lg-3 col-md-3 col-sm-3">
            <div className="tarjeta">
              <span style={{fontSize: '1em', color: '#fff',margin:'0 auto'}}>
                <FontAwesomeIcon icon={faUsersCog} size="lg"/>
              </span>
              <div className="tarjeta-cuerpo">
                <h5>SERVICIO TÉCNICO</h5>
                <p>Mantenimiento preventivo y correctivo de equipos de cómputo. Reparacion de Laptop, impresoras, cableado estructurado</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-3">
            <div className="tarjeta">
              <span style={{fontSize: '1em', color: '#fff',margin:'0 auto'}}>
                <FontAwesomeIcon icon={faNetworkWired} size="lg"/>
              </span>
              <div className="tarjeta-cuerpo">
                <h5>VENTA DE EQUIPOS</h5>
                <p>Ventas de equipos informáticos repuestos y suministros</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-3">
            <div className="tarjeta">
              <span style={{fontSize: '1em', color: '#fff',margin:'0 auto'}}>
                <FontAwesomeIcon icon={faHandsHelping} size="lg"/>
              </span>
              <div className="tarjeta-cuerpo">
                <h5>OUTSOURCING</h5>
                <p>Outsourcing de Impresión administrada costo cero. Outsourcing de servicio informático.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-3">
            <div className="tarjeta">
              <span style={{fontSize: '1em', color: '#fff',margin:'0 auto'}}>
                <FontAwesomeIcon icon={faShoppingCart} size="lg"/>
              </span>
              <div className="tarjeta-cuerpo">
                <h5>VENTA DE SOFTWARE</h5>
                <p>Licencias de Microsoft Windows y Office para los PCs de su Oficina. Licencias para su Servidores windows server, etc</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default IconosServicios;
