import React, { Component } from 'react';
import {NavLink} from "react-router-dom";
import imganen1 from './copia1.jpg';
import imganen2 from './copia2.jpg';
import './OutsorcingdeImpresion.css';

class OutsorcingdeImpresion extends Component {

  render()
  {
    return (
      <div className="container-fluid OutsorcingdeImpresion">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-3 animated fadeInLeft">
          <div className="lista_botones">
            <div className="list-group lista_botones">
              <NavLink type="button" className="list-group-item list-group-item-action" to="/alquiler-de-equipos/outsorcing-de-impresion" activeClassName="active">OUTSORCING DE IMPRESIÓN</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/alquiler-de-equipos/outsorcing-de-laptop" activeClassName="active">OUTSORCING DE LAPTOP</NavLink>
            </div>
          </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 animated fadeInDownBig">
          <h3>Alquiler de Equipos</h3>
          <div className="parrafo">
            <h4>OUTSOURCING DE IMPRESIÓN</h4>
            <p>
            Necesitas cambiar tu equipo obsoleto o dañado de impresión, pero no quieres invertir grandes cantidades ni preocuparte con que equipo es el más apto para tus necesidades (una impresora cara pero con suministros baratos o una impresora barata con suministros caros).
            Tampoco quieres tener dolor de cabeza de mantener o reparar equipo de impresión, necesitas una forma rápida y sencilla de reducir costos de consumibles, minimizar inversión en equipos de impresión y no gastar en soporte mantenimiento o reparación de impresoras. Quieres la tranquilidad de que tu equipo y servicio estén respaldados.
            En JMH Glabal Network sabemos que los costos de impresión van más allá de los cartuchos de tinta y toner. Los costos incluyen la compra, mantenimiento y reparación de las impresoras, fotocopiadoras u otro equipo de impresión. También incluyen los gastos de ayuda tipo “help desk”.
            Por eso en vez de comprar tú equipo de impresión y asumir toda la responsabilidad de mantenerlos operando, abastecidos de consumibles y en buen estado de funcionamiento.</p>

            <p>HELP SYSTEM ofrece el programa de “Outsourcing de impresión administrada costo cero”. Dicho programa funciona como un plan mensual, bimestral o trimestral de pag, donde por un monto recibes equipo de impresión y/o equipos multifuncional, capacidad de imprimir miles de páginas al mes y el soporte técnico para que siempre puedas imprimir sin preocuparte, si el equipo se descompone o si necesita mantenimiento preventivo o correctivo.</p>
          </div>


          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 animated fadeInDownBig">
            <div className="card" style={{width: '100%',border:'none',marginBottom:'10px'}}>
              <img src={imganen1} className="card-img-top" alt="Imagen 1"/>
            </div>
            <div className="card" style={{width: '100%',border:'none'}}>
              <img src={imganen2} className="card-img-top" alt="Imagen 1"/>
            </div>
          </div>
        </div>
        <hr style={{height: '2px',background: 'darkgray'}}/>
      </div>
    );
  }
}

export default OutsorcingdeImpresion;
