import React, { Component } from 'react';
import video1 from './video.mp4';
import video2 from './video2.mp4';
import './SlideVideos.css';

class SlideVideos extends Component {

  render()
  {
    return (
      <div className="SlideVideos">
        <div className="row">
          <div className="col-lg-6">
            <div className="tarjeta">
              <div className="tarjeta-cuerpo">
                <video width="100%" height="100%" controls>
                  <source src={video1} type="video/mp4"/>
                  <source src={video1} type="video/ogg"/>
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="tarjeta">
              <div className="tarjeta-cuerpo">
                <video width="100%" height="100%" controls>
                  <source src={video2} type="video/mp4"/>
                  <source src={video2} type="video/ogg"/>
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default SlideVideos;
