import React, { Component } from 'react';
import producto1 from './copia1.jpg';
import producto2 from './copia2.jpg';
import producto3 from './copia3.jpg';
import producto4 from './copia4.jpg';
import './SlideProductos.css';

class SlideProductos extends Component {

  render()
  {
    return (
      <div className="container-fluid SlideProductos">
      <br/>
        <h3>Nuestros Productos</h3>
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-12 contenedor_producto animated wobble">
            <img src={producto1} width="100%" height="300px" alt=""/>
            <p className="btn btn-secondary">plotter HP designjet T120</p>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 contenedor_producto animated bounceIn">
            <img src={producto2} width="100%" height="300px" alt=""/>
            <p className="btn btn-secondary">Laptop HP Enterprise T4550</p>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 contenedor_producto animated bounceInDown">
            <img src={producto3} width="100%" height="300px" alt=""/>
            <p className="btn btn-secondary">Impresora Epson L465</p>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 contenedor_producto animated jello">
            <img src={producto4} width="100%" height="300px" alt=""/>
            <p className="btn btn-secondary">Computadora Gamer Force Blue OX850</p>
          </div>
        </div>
      </div>
    );
  }
}

export default SlideProductos;
