import React, { Component } from 'react';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt,faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
// import { faIdCard, faClock } from '@fortawesome/free-regular-svg-icons';
import { faFacebookF,faTwitter,faInstagram } from '@fortawesome/free-brands-svg-icons';
import './DatosEmpresa.css';
class DatosEmpresa extends Component {

  render()
  {
    return (
      <div className="DatosEmpresa animated slideInDown">
        <div className="datos">
          <div className="subdato">
            <Link className="link_borde" to="/intranet/institucion"><FontAwesomeIcon icon={faMobileAlt} size="lg"/></Link>
            <span>(01) 6335555</span>
          </div>
          <div className="subdato">
            <Link className="link_borde" to="/intranet/institucion"><FontAwesomeIcon icon={faMapMarkerAlt} size="lg"/></Link>
            <span>Cibertec - SJL</span>
          </div>
        </div>
        <div className="redes_sociales">
          <a href="https://www.facebook.com" className=""><FontAwesomeIcon icon={faFacebookF} size="lg"/></a>
          <a href="https://www.twitter.com"><FontAwesomeIcon icon={faTwitter} size="lg"/></a>
          <a href="https://www.instagram.com"><FontAwesomeIcon icon={faInstagram} size="lg"/></a>
        </div>
      </div>
    );
  }
}

export default DatosEmpresa;
