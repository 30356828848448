import React, { Component } from 'react';
import {NavLink} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import imganen1 from './camaras1.jpg';
import imganen2 from './camaras2.jpg';
import './InstalaciondeCamaras.css';

class InstalaciondeCamaras extends Component {

  render()
  {
    return (
      <div className="container-fluid InstalaciondeCamaras">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-3 animated fadeInLeft">
          <div className="lista_botones">
            <div className="list-group lista_botones">
              <NavLink type="button" className="list-group-item list-group-item-action" to="/servicio-de-redes-y-camaras/cableado-de-red" activeClassName="active">CABLEADO DE RED</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/servicio-de-redes-y-camaras/instalacion-de-camaras" activeClassName="active">INSTALACIÓN DE CÁMARAS</NavLink>
            </div>
          </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 animated fadeInDownBig">
          <h3>INSTALACIÓN DE CÁMARAS</h3>
          <div className="parrafo">
            <p>Tenemos amplia experiencia en mantenimientos preventivos así como correctivos; contamos con técnicos certificados y las herramientas necesarias para realizar los trabajos con calidad.</p>
            <p>HELP SYSTEM le ofrece una amplia gama de nuevas cámaras de vigilancia que le permite responder a la mayoría de las necesidades relacionadas con la vigilancia en las empresas. Las cámaras IP, más fáciles de usar y con una mayor flexibilidad que las cámaras de vigilancia tradicionales, resuelven los problemas de calidad de imagen, de accesibilidad y de almacenamiento que presentan las cámaras analógicas. De este modo, puede visualizar o grabar el vídeo desde cualquier parte a través de internet.</p>
            <p>Puede elegir la solución que mejor se adapte a sus necesidades cámaras de interior o exterior, monitorización, infrarrojos, sensores para entornos poco iluminados.</p>
            <p>Las soluciones que ofrecemos incluyen el software de vigilancia, suministrado con las cámaras sin coste añadido, le garantiza eficacia en el control y la gestión de la vigilancia de la empresa.</p>
          </div>
          <div className="parrafo">
            <h4>NUESTRO SERVICIO DE INSTALACIÓN DE CÁMARAS DE SEGURIDAD BÁSICAS COMPRENDE:</h4>
            <ul>
              <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Evaluación del área para instalar cámaras de seguridad.</li>
              <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Venta de equipos, accesorios y materiales.</li>
              <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Instalación de Cableado.</li>
              <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Instalación y configuración de conectores.</li>
              <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Configuración DVR o NVR.</li>
              <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Asesoría integral de seguridad.</li>
            </ul>
          </div>

          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 animated fadeInDownBig">
            <div className="card" style={{width: '100%',border:'none',marginBottom:'10px'}}>
              <img src={imganen1} className="card-img-top" alt="Imagen 1"/>
            </div>
            <div className="card" style={{width: '100%',border:'none'}}>
              <img src={imganen2} className="card-img-top" alt="Imagen 1"/>
            </div>
          </div>
        </div>
        <hr style={{height: '2px',background: 'darkgray'}}/>
      </div>
    );
  }
}

export default InstalaciondeCamaras;
