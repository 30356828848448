import React, { Component } from 'react';
import {NavLink} from "react-router-dom";
import imganen1 from './mision2.jpg';
import imganen2 from './mision1.jpg';
import './MisionVision.css';

class MisionVision extends Component {

  render()
  {
    return (
      <div className="container-fluid MisionVision">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-4 animated fadeInLeft">
          <div className="lista_botones">
            <div className="list-group lista_botones">
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestras-empresas" activeClassName="active">NUESTRAS EMPRESAS</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/mision-y-vision" activeClassName="active">MISIÓN Y VISIÓN</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/por-que-elegirnos" activeClassName="active">POR QUE ELEGIRNOS</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestra-experiencia" activeClassName="active">NUESTRA EXPERIENCIA</NavLink>
            </div>
          </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 animated fadeInDownBig">
            <h3>VISIÓN</h3>
            <p>“La consolidación y el liderazgo en el sector del mantenimiento de equipos del sector Tecnologico, Informatico y Redes, a través del constante perfeccionamiento empresarial.Lograr el reconocimiento de nuestros servicios por la credibilidad de los clientes, calidad de trabajo, rapidez, cumplimiento y responsabilidad”</p>
              <h3>MISIÓN</h3>
              <p>"Somos una empresa orientada a brindar servicios de calidad, con el respaldo de nuestra experiencia en el mantenimiento de equipos del sector Tecnologico, Informatico y Redes; ofreciendo las mejores soluciones con un Staff de profesionales altamente competentes y comprometidos con los estándares de calidad, seguridad"</p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 animated fadeInDownBig">
            <div className="card" style={{width: '50%',border:'none',marginBottom:'10px',height:'200px'}}>
              <img src={imganen1} className="card-img-top" alt="Imagen 1"/>
            </div>
            <div className="card" style={{width: '50%',border:'none',height:'200px'}}>
              <img src={imganen2} className="card-img-top" alt="Imagen 1"/>
            </div>
          </div>
        </div>
        <hr style={{height: '2px',background: 'darkgray'}}/>
      </div>
    );
  }
}

export default MisionVision;
