import React, { Component } from 'react';
import './Mapa.css';

class Mapa extends Component {

  render()
  {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-3 animated fadeInLeft">
            <h3>CONTÁCTENOS:</h3>
            <p>Todos los días es un nuevo día para nosotros y trabajamos muy duro para satisfacer a nuestros clientes en todas partes.</p>
            <h3>HELPS SYSTEM</h3>
            <p>Dirección: Av. Wisse Mz. N Lt.8 – SJL – Lima – Perú <br/>Telf: 01333 4444</p>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 animated fadeInDownBig">
            <h3>QUIENES SOMOS</h3>
            <p><strong>HELPS SYSTEM</strong> es una empresa especialista en soluciones integrales de Impresión, para ello brinda servicios Integral de Outsourcing (Tercerización) de Impresión para ello provee equipos (fotocopiadoras, computadoras, impresoras, etc) asumiendo todos los costos directos e indirectos.</p>
            <p>El principal objetivo que tenemos es ofrecer nuestros servicios especializados en Fotocopiadoras, impresoras y ventas de insumo de gran calidad al precio más bajo para tener la preferencia de nuestros clientes.</p>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <iframe id="mapa" title="Mapa" className="animated fadeInRight" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d2087.6646474372965!2d-76.9863329133673!3d-11.95872568768128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2spe!4v1560379101534!5m2!1ses-419!2spe" width="100%" height="300" frameBorder="0" style={{border:'0px'}} allowFullScreen></iframe>
          </div>
        </div>
      </div>
    );
  }
}

export default Mapa;
