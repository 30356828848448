import React, { Component } from 'react';
import {NavLink} from "react-router-dom";
import './Navegador.css';
import logo from './logo1.jpg';

class Navegador extends Component {

  render()
  {
    return (
      <div className="Navegador">
        <div className="mobile">
        </div>
        <div className="escritorio">
          <div className="logo">
            <NavLink className="link_borde" to="/"><img src={logo} alt="" /></NavLink>
          </div>
          <div className="nav_g">
            <ul>
              <li className="item_menu"><NavLink className="link_borde" to="/" activeClassName="activo">INICIO</NavLink></li>
              <li className="item_menu">
                <span className="link_borde">NOSOTROS</span>
                <ul className="cuerpo_submenu">
                  <li><NavLink className="link_borde" to="/nuestras-empresas" activeClassName="activo">NUESTRAS EMPRESAS</NavLink></li>
                  <li><NavLink className="link_borde" to="/mision-y-vision" activeClassName="activo">MISIÓN Y VISIÓN</NavLink></li>
                  <li><NavLink className="link_borde" to="/por-que-elegirnos" activeClassName="activo">POR QUE ELEGIRNOS</NavLink></li>
                  <li><NavLink className="link_borde" to="/nuestra-experiencia" activeClassName="activo">NUESTRA EXPERIENCIA</NavLink></li>
                </ul>
              </li>
              <li className="item_menu">
                <span className="link_borde">SERVICIOS</span>
                <ul className="cuerpo_submenu">
                  <li><NavLink className="link_borde" to="/nuestros-servicios" activeClassName="activo">NUESTROS SERVICIOS</NavLink></li>
                  <li><NavLink className="link_borde" to="/alquiler-de-equipos" activeClassName="activo">ALQUILER DE EQUIPOS</NavLink></li>
                  <li><NavLink className="link_borde" to="/servicio-de-redes-y-camaras" activeClassName="activo">SERVICIO DE REDES Y CÁMARAS</NavLink></li>
                </ul>
              </li>
              <li className="item_menu">
                <span className="link_borde">PRODUCTOS</span>
                <ul className="cuerpo_submenu">
                  <li><NavLink className="link_borde" to="/repuestos-para-impresoras" activeClassName="activo">REPUESTOS PARA IMPRESORAS</NavLink></li>
                  <li><NavLink className="link_borde" to="/impresoras" activeClassName="activo">IMPRESORAS</NavLink></li>
                  <li><NavLink className="link_borde" to="/copiadoras" activeClassName="activo">COPIADORAS</NavLink></li>
                </ul>
              </li>
              <li className="item_menu"><NavLink className="link_borde" to="/noticias" activeClassName="activo">NOTICIAS</NavLink></li>
              <li className="item_menu"><NavLink className="link_borde" to="/contacto" activeClassName="activo">CONTACTO</NavLink></li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Navegador;
