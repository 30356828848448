import React, { Component } from 'react';
import {NavLink} from "react-router-dom";
import imganen1 from './valores2.jpg';
import imganen2 from './valores1.jpg';
import './NuestraExperiencia.css';

class NuestraExperiencia extends Component {

  render()
  {
    return (
      <div className="container-fluid NuestraExperiencia">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-3 animated fadeInLeft">
          <div className="lista_botones">
            <div className="list-group lista_botones">
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestras-empresas" activeClassName="active">NUESTRAS EMPRESAS</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/mision-y-vision" activeClassName="active">MISIÓN Y VISIÓN</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/por-que-elegirnos" activeClassName="active">POR QUE ELEGIRNOS</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestra-experiencia" activeClassName="active">NUESTRA EXPERIENCIA</NavLink>
            </div>
          </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-5 animated fadeInDownBig margin_derecho">
            <h3>NUESTROS VALORES Y EXPERIENCIAS</h3>
            <h5>Experiencia</h5>
            <p>Contamos con mas de 4 años de experiencia e innovación continua, lo que nos ha permitido diversificar nuestros rubros, con la finalidad de satisfacer las necesidades de nuestros clientes</p>
            <h5>Atención Inmediata</h5>
            <p>Ofrecemos un servicio especializado cuya finalidad es brindar de forma inmediata, eficaz y oportuna la atención a la necesidad de los clientes</p>
            <h5>Responsabilidad</h5>
            <p>Los clientes son el centro de todo lo que hacemos, escuchamos atentamente sus necesidades y requerimientos.</p>
            <h5>Calidad</h5>
            <p>Trabajamos para alcanzar la satisfacción de nuestros clientes buscando siempre el perfeccionamiento de los servicios que brindamos.</p>
            <h5>Atención personalizada</h5>
            <p>Contamos con profesionales que lo asesoraran y desarrollaran junto a usted la solución que se adecue mejor a sus necesidades.</p>
            <h5>Confidencialidad</h5>
            <p><strong>HELP SYSTEMS</strong> se compromete en guardar en estricta reserva la información proporcionada por el cliente.</p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 animated fadeInDownBig">
            <div className="card" style={{width: '80%',border:'none',marginBottom:'10px'}}>
              <img src={imganen1} className="card-img-top" alt="Imagen 1"/>
            </div>
            <div className="card" style={{width: '60%',border:'none'}}>
              <img src={imganen2} className="card-img-top" alt="Imagen 1"/>
            </div>
          </div>
        </div>
        <hr style={{height: '2px',background: 'darkgray'}}/>
      </div>
    );
  }
}

export default NuestraExperiencia;
