import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import slide1 from './slide1.jpg';
import slide2 from './slide2.jpg';
import slide3 from './slide3.jpg';
import './SlidePrincipal.css';

class SlidePrincipal extends Component {

  render()
  {
    const fondo = {
      background: 'rgba(28, 172, 230, 0.34)',
      padding:'10px'
    };
    const divStyle = {
      top:'25%',
      width: "30%"
    };
    return (
      <Carousel className="SlidePrincipal">
        <Carousel.Item>
          <img
            className="d-block w-100 animated flipInX"
            src={slide1}
            alt="First slide"
            height={500}
          />
          <Carousel.Caption style={divStyle}>
          <div style={fondo}>
            <h3 className="animated slideInUp" style={{'fontSize':'30px',letterSpacing: '.02em'}}>Venta y servicio técnico de servidores, computadoras y laptops</h3>
            <p>Venta de Computadoras, Notebooks, Servidores, Impresoras,Netbook, Monitores y Software. accesorios,  Placa principal, Procesadores Intel , Discos Duros,
Memorias, Servidores Hp, Lenovo, Dell.</p>
          </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 animated rollIn"
            src={slide2}
            alt="Third slide"
            height={500}
          />
          <Carousel.Caption style={divStyle}>
          <div style={fondo}>
            <h3 className="animated slideInUp" style={{'fontSize':'30px',letterSpacing: '.02em'}}>Reparación de Laptop, PCs e Impresoras</h3>
            <p>Realizamos revisión y Mantenimientos de impresoras en su Oficina
Técnicos con más de 16 años de experiencia en el mercado.
Soluciones profesionales en reparación de laptops, solicita nuestro servicio.
contamos con amplio stock de repuestos y accesorios</p>
          </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 animated zoomInDown"
            src={slide3}
            alt="Third slide"
            height={500}
          />
          <Carousel.Caption style={divStyle}>
          <div style={fondo}>
            <h3 className="animated slideInUp" style={{'fontSize':'30px',letterSpacing: '.02em'}}>Venta de suministros de impresoras</h3>
            <p>Venta de impresoras y suministros
tenemos en toda las marcas y modelos.
Consumibles y accesorios para impresoras y multifunción
Pedidos a través de teléfono y correo electrónico.
Solicite el envío por Delivery a su oficina o domicilio.</p>
          </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

export default SlidePrincipal;
