import React, { Component } from 'react';
import imganen1 from './noti1.jpg';
import imganen2 from './noti2.jpg';
import imganen3 from './noti3.jpg';
import imganen4 from './noti4.jpg';
import './Noticias.css';

class Noticias extends Component {

  render()
  {
    return (
      <div className="container-fluid Noticias">
        <h3>Últimas noticias</h3>
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-12">
            <img className="image_de_fondo" src={imganen1} alt="imahgen"/>
            <h6>TÍTULO NOTICIA 1</h6>
            <p>12 Julio 2019</p>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <img className="image_de_fondo" src={imganen2} alt="imahgen"/>
            <h6>TÍTULO NOTICIA 2</h6>
            <p>18 Junio 2019</p>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <img className="image_de_fondo" src={imganen3} alt="imahgen"/>
            <h6>TÍTULO NOTICIA 3</h6>
            <p>30 Abril 2019</p>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <img className="image_de_fondo" src={imganen4} alt="imahgen"/>
            <h6>TÍTULO NOTICIA 4</h6>
            <p>21 Mayo 2019</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Noticias;
