import React, { Component } from 'react';
import {NavLink} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import imganen1 from './laptop1.jpg';
import imganen2 from './laptop2.jpg';
import imganen3 from './laptop3.jpg';
import './MantenimientoReparaciondeLaptop.css';

class MantenimientoReparaciondeLaptop extends Component {

  render()
  {
    return (
      <div className="container-fluid MantenimientoReparaciondeLaptop">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-3 animated fadeInLeft">
          <div className="lista_botones">
            <div className="list-group lista_botones">
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestros-servicios/mantenimiento-preventivo-de-pc" activeClassName="active">MANTENIMIENTO PREVENTICO Y CORRECTIVO DE PC</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestros-servicios/mantenimiento-y-reparacion-de-laptop" activeClassName="active">MANTENIMIENTO Y REPARACIÓN DE LAPTOP</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestros-servicios/mantenimiento-y-reparacion-de-proyectores" activeClassName="active">MANTENIMIENTO Y REPARACIÓN DE PROYECTORES</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestros-servicios/mantenimiento-y-reparacion-de-impresoras-y-copiadoras" activeClassName="active">MANTENIMIENTO Y REPARACIÓN DE IMPRESORAS Y COPIADORAS</NavLink>
            </div>
          </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 animated fadeInDownBig">
              <h3>Nuestros Servicios</h3>
              <div className="parrafo">
                <h4>Servicio de reparación y mantenimiento preventivo de laptops</h4>
                <p>Contamos con la experiencia necesaria para la reparación de Laptops. Reparamos todos los modelos y marcas de laptops que se encuentran actualmente en el mercado.</p>
                <p>Cualquier problema que presente su laptop, no enciende, este rota la pantalla o esta más lenta de lo normal en Sistema operativo (windows), la batería no carga, hace sonido o ruido extraño, tienes virus o spyware, entre otros, no te preocupes nosotros tenemos la solución, contamos con personal técnico capacitado para resolver todas las fallas. Así mismo contamos con repuestos y accesorios para laptops.</p>
              </div>
              <div className="parrafo">
                <h4>Soporte para Pc, Notebooks, Netbooks y All In One:</h4>
                <ul>
                  <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Limpieza de polvo y humedad de laptop, interna y externa.</li>
                  <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Actualización de sistema operativo, antivirus y programas utilitarios.</li>
                  <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Revisión de disco duro y defragmentación.</li>
                  <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Eliminación de virus/malware en caso de que ingresaran al sistema.</li>
                  <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Instalación de componentes y/o periféricos de hardware.</li>
                  <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Recuperación de Datos.</li>
                  <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Servicio de reparación y mantenimiento correctivo de laptops.</li>
                  <br/>

                  <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Cambio de pantalla (si se encuentra rota o muy rayada).</li>
                  <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Cambio de inversor o de lámpara (si la pantalla de su laptop se ve oscura pero alcanzas a ver algo).</li>
                  <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Reparación de chip de vídeo (si su pantalla no muestra nada ni conectando a otro monitor).</li>
                  <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Reparación de plug de corriente (si tienes que andar moviendo el cable de tu adaptador para poder encender tu laptop).</li>
                  <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Reparación de fuente de alimentación (falla común equipo ya no quiere prender).</li>
                  <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Reparación o cambio de teclado (si le cayo agua o simplemente dejo de escribir algunas teclas).</li>
                  <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Cambio de ventilador o cooler procesador (se calienta demasiado y en ciertos aplicaciones se apaga después de un tiempo).</li>
                  <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Cambio de procesador o memorias (si la falla presenta constantemente al intentar formatear el equipo).</li>
                  <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Reparación de tu adaptador de corriente (si el cable esta roto o no pasa corriente adecuado al equipo).</li>
                  <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Cambio de disco duro y/o recuperación de información (si se daño o simplemente ya esta lleno disco duro).</li>
                  <li><FontAwesomeIcon icon={faChevronRight} size="lg"/> Cambio de tarjeta madre o placa (si no se puede reparar con ninguno de los servicios anteriores detallados).</li>

                </ul>
              </div>

          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 animated fadeInDownBig">
            <div className="card" style={{width: '100%',border:'none',marginBottom:'10px'}}>
              <img src={imganen1} className="card-img-top" alt="Imagen 1"/>
            </div>
            <div className="card" style={{width: '100%',border:'none',marginBottom:'10px'}}>
              <img src={imganen2} className="card-img-top" alt="Imagen 1"/>
            </div>
            <div className="card" style={{width: '100%',border:'none',marginBottom:'10px'}}>
              <img src={imganen3} className="card-img-top" alt="Imagen 1"/>
            </div>
          </div>
        </div>
        <hr style={{height: '2px',background: 'darkgray'}}/>
      </div>
    );
  }
}

export default MantenimientoReparaciondeLaptop;
