import React, { Component } from 'react';
import {NavLink} from "react-router-dom";
import imganen1 from './pc1.jpg';
import imganen2 from './pc2.jpg';
import imganen3 from './pc3.jpg';
import './MantenimientoPreventivodePc.css';

class MantenimientoPreventivodePc extends Component {

  render()
  {
    return (
      <div className="container-fluid MantenimientoPreventivodePc">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-3 animated fadeInLeft">
          <div className="lista_botones">
            <div className="list-group lista_botones">
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestros-servicios/mantenimiento-preventivo-de-pc" activeClassName="active">MANTENIMIENTO PREVENTICO Y CORRECTIVO DE PC</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestros-servicios/mantenimiento-y-reparacion-de-laptop" activeClassName="active">MANTENIMIENTO Y REPARACIÓN DE LAPTOP</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestros-servicios/mantenimiento-y-reparacion-de-proyectores" activeClassName="active">MANTENIMIENTO Y REPARACIÓN DE PROYECTORES</NavLink>
              <NavLink type="button" className="list-group-item list-group-item-action" to="/nuestros-servicios/mantenimiento-y-reparacion-de-impresoras-y-copiadoras" activeClassName="active">MANTENIMIENTO Y REPARACIÓN DE IMPRESORAS Y COPIADORAS</NavLink>
            </div>
          </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 animated fadeInDownBig">
          <h3>Nuestros Servicios</h3>
          <div className="parrafo">
            <h4>Mantenimiento preventivo y correctivo de equipos de cómputo</h4>
            <p>En Help System contamos con el servicio de mantenimiento preventivo y correctivo de equipo de computo así como también actualizaciones de hardware y software al mejor precio del mercado. Gracias a nuestros técnicos calificados podemos garantizar el mejor servicio para su equipo de cómputo, ya sea de escritorio o portátil.</p>
            <p>Es importante dar un servicio de mantenimiento preventivo a nuestra computadora, para evitar que esta falle en el momento más inoportuno.</p>
            <p>El polvo y humedad puede formar una capa térmica que eleva la temperatura y reduce el tiempo de vida de la computadora o hasta quemar los componentes internos de la misma.</p>
            <p>Hay que tener en cuenta además que existen dentro de una computadora piezas electromecánicas que se desgastan más con el uso y el tiempo: los cabezales de lecturas, los discos duros, los ventiladores de fuente de poder, cooler de procesador ente otras piezas.</p>
            <p>El servicio de mantenimiento preventivo no solo consiste en dejar limpio y libre de polvo y humedad de componentes internos de la computadora, también consta de un mantenimiento al sistema operativo, limpieza de archivos obsoletos (archivos temporales que no fueron eliminados), desfragmentación del disco duro, limpieza del registro, con esto prevenir fallas y mejorar el rendimiento del sistema operativo.</p>
          </div>


          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 animated fadeInDownBig">
            <div className="card" style={{width: '100%',border:'none',marginBottom:'10px'}}>
              <img src={imganen1} className="card-img-top" alt="Imagen 1"/>
            </div>
            <div className="card" style={{width: '100%',border:'none',marginBottom:'10px'}}>
              <img src={imganen2} className="card-img-top" alt="Imagen 1"/>
            </div>
            <div className="card" style={{width: '100%',border:'none',marginBottom:'10px'}}>
              <img src={imganen3} className="card-img-top" alt="Imagen 1"/>
            </div>
          </div>
        </div>
        <hr style={{height: '2px',background: 'darkgray'}}/>
      </div>
    );
  }
}

export default MantenimientoPreventivodePc;
