import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt,faMapMarkerAlt,faEnvelopeOpen,faSearch } from '@fortawesome/free-solid-svg-icons';
import './Contacto.css';

class Contacto extends Component {

  render()
  {
    return (
      <div className="container-fluid Contacto">
        <h3>Escríbanos un Mensaje</h3>
        <div className="row">


        <div className="col-lg-4 col-md-4 col-sm-4 animated fadeInDownBig">
          <div className="card" style={{width: '100%',padding:'30px',marginBottom:'10px'}}>
            <h4><FontAwesomeIcon icon={faMapMarkerAlt} size="lg"/> <strong>HELPS SYSTEM S.A.C.</strong></h4>
            <p>Calle Ampuero de las infantas<br/>Mz C5 Lt. 38 <br/>AA.HH. 10 de Octubre<br/>San Juan de Lurigancho</p>

            <hr style={{height: '1px',background: 'darkgray'}}/>
            <div className="contenedor_contacto">
              <div className="icono">
                <FontAwesomeIcon icon={faMobileAlt} size="lg"/>
              </div>
              <div className="parrafo">
                <p>01-585-9376</p>
                <p>01-977-930-913</p>
              </div>
            </div>

            <hr style={{height: '1px',background: 'darkgray'}}/>
            <div className="contenedor_contacto">
              <div className="icono">
                <FontAwesomeIcon icon={faEnvelopeOpen} size="lg"/>
              </div>
              <div className="parrafo">
                <p>soporte@helpsystem.com</p>
                <p>informes@helpsystem.com</p>
              </div>
            </div>

            <hr style={{height: '1px',background: 'darkgray'}}/>
            <div className="contenedor_contacto">
              <div className="icono">
                <strong><FontAwesomeIcon icon={faSearch} size="lg"/></strong>
              </div>
              <div className="parrafo">
                <p>www.helpsystem.com</p>
                <p>www.helpsystemsac.com</p>
              </div>
            </div>
          </div>
        </div>


          <div className="col-lg-8 col-md-8 col-sm-8">
            <div className="formulario">
              <input className="form-control form-control-lg" type="text" placeholder="Nombres"/>
              <br/>
              <input className="form-control form-control-lg" type="text" placeholder="Apellidos"/>
              <br/>
              <input className="form-control form-control-lg" type="text" placeholder="Empresa"/>
              <br/>
              <input className="form-control form-control-lg" type="text" placeholder="Email"/>
              <br/>
              <input className="form-control form-control-lg" type="text" placeholder="Teléfono"/>
              <br/>
              <input className="form-control form-control-lg" type="text" placeholder="Asunto"/>
              <br/>
              <textarea class="form-control" id="mensaje" rows="4" placeholder="Mensaje"></textarea>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default Contacto;
