import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-solid-svg-icons';
import './CopyRight.css';

class CopyRight extends Component {

  render()
  {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 CopyRight">
            <h5><FontAwesomeIcon icon={faCopyright} size="lg"/> copyright 2019</h5>
            <p>
            Developer Mautino Borja, Yeferson John<br/>
            Developer Orbegoso Loaysa, Carlos<br/>
            Developer Gerónimo Jara, Javier David</p>
          </div>
        </div>
      </div>
    );
  }
}

export default CopyRight;
