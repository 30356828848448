import React, { Component } from 'react';
import producto1 from './hp.jpg';
import producto2 from './logo-lenovo.png';
import producto3 from './kyo.png';
import producto4 from './seagate.png';
import producto5 from './canon.png';
import producto6 from './toshiba.png';
import './SlideClientes.css';

class SlideClientes extends Component {

  render()
  {
    return (
      <div className="container-fluid SlideClientes">

        <hr style={{height: '2px',background: 'darkgray'}}/>
        <h3>Nuestros Clientes</h3>
        <br/>
        <div className="row">
          <div className="col-lg-2 col-md-3 col-sm-12 contenedor_cliente">
            <img src={producto1} width="100%" height="80px" alt=""/>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-12 contenedor_cliente">
            <img src={producto2} width="100%" height="80px" alt=""/>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-12 contenedor_cliente">
            <img src={producto3} width="100%" height="80px" alt=""/>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-12 contenedor_cliente">
            <img src={producto4} width="100%" height="80px" alt=""/>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-12 contenedor_cliente">
            <img src={producto5} width="100%" height="80px" alt=""/>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-12 contenedor_cliente">
            <img src={producto6} width="100%" height="80px" alt=""/>
          </div>
        </div>
        <br/>
        <hr style={{height: '2px',background: 'darkgray'}}/>


      </div>
    );
  }
}

export default SlideClientes;
